import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "./navbar.css";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "row-reverse",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Container>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ direction: "rtl" }}
          >
            <Nav className="ms-auto" defaultActiveKey="#home">
              <Nav.Item>
                <ScrollLink
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={300}
                >
                  خانه
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  to="academy"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={300}
                >
                  اکادمی
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={300}
                >
                  سرویس ها
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  to="freeConsultatiom"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={300}
                >
                  مشاوره رایگان
                </ScrollLink>
              </Nav.Item>

              <Nav.Item>
                <ScrollLink
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={300}
                >
                  درباره ما
                </ScrollLink>
              </Nav.Item>
              <Nav.Item>
                <ScrollLink
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={300}
                >
                  تماس با ما
                </ScrollLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </div>
    </Navbar>
  );
}

export default NavBar;
