import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        {/* <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I am fluent in classics like
              <i>
                <b className="purple"> C++ and Python. </b>
              </i>
              <br />
              <br />
              I debug more than I code.
              <br />
              <br />
              My pronouns are{" "}
              <i>
                <b>Jack of All Trades / Master of none</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row> */}
        <Row>
          <Col
            md={4}
            className="home-about-social"
            style={{ direction: "ltr" }}
          >
            <h1 className="feno-logo">Feno group</h1>
            {/* <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/sukrutrahane"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/sukrutrahane"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/sukrutrahane/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/sukkkrut"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul> */}
          </Col>
          <Col md={4} className="home-about-social">
            <h3>ساعت کاری</h3>
            <p>شنبه تا چهارشنبه : 8 صبح - 16 عصر</p>
            <p>پنحشنبه و جمعه : تعطیل</p>
          </Col>
          <Col
            md={4}
            className="home-about-social"
            style={{ direction: "rtl", textAlign: "right !important" }}
          >
            <h3>ارتباط با ما</h3>
            <p>شماره تماس : 09190535187 </p>
            <p>شماره ثابت : 02128427488</p>
            <p>آدرس : تهران , زعفرانیه خیابان اعجازی (آصف)پلاک 59 واحد 2</p>
            {/* Add any other contact info here */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
