import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "./HomModalForm.css"; // Import the CSS file

export default function HomModalForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    note: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission here
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <Grid container spacing={2} className="form-box">
        <Grid item xs={12}>
          <TextField
            label="نام و نام خانوادگی"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            className="text-field"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="ایمیل"
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            fullWidth
            required
            className="text-field"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="شماره تماس"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            required
            className="text-field"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="یادداشت (اختیاری)"
            name="note"
            value={formData.note}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            className="text-field"
            dir="rtl"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="submit-button"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
