import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import galaxy from "../../Assets/galaxy.svg";
import rocket from "../../Assets/rocket.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import ServicesList from "../ServicesList/ServicesList";
import "./home.css";
import academyicon from "../../Assets/academy.svg";

function Home() {
  return (
    <section>
      <Container
        fluid
        className="home-section"
        id="home"
        style={{ direction: "rtl", height: "100vh" }}
      >
        <Particle />
        <Container className="home-content">
          <Row>
            <Col
              md={7}
              className="home-header"
              style={{
                direction: "rtl",
                textAlign: "right",
                paddingRight: "5vw",
              }}
            >
              <h1 style={{ paddingBottom: 15 }} className="heading">
                خوش آمدید!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                ما
                <strong className="feno-logo"> Feno </strong>
                هستیم
              </h1>

              <div
                style={{
                  direction: "rtl",
                  textAlign: "right",
                  marginTop: "5vh",
                  marginBottom: "10vh",
                  height: "10vh",
                }}
              >
                <Type
                  text={[
                    "فنو یک ایده ی جذاب از یک فروشنده ی معمولی بود",
                    "که میتونست هر چیزی رو بفروشه",
                    "و البته اونو آموزش بده!",
                    "فروش نامحدود ...",
                  ]}
                />
              </div>
            </Col>
            <Col
              md={5}
              style={{
                paddingBottom: 20,
                position: "relative",
                textAlign: "center",
              }}
            >
              <img src={galaxy} alt="home pic" className="img-fluid galaxy" />
              <img src={rocket} className="rocket" alt="home pic" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={academyicon} alt="academy-icon" style={{ width: "20%" }} />
        <h3 style={{ width: "70%", marginBottom: "5vh", marginTop: "5vh" }}>
          آکادمی فنو بزرگترین مرجع تخصصی فروش نوآورانه در کشور با بیش از دو دهه
          سابقه درخشان، پیشتاز در ارائه خدمات مدرن و نو
        </h3>
        <p style={{ width: "55%" }}>
          تا کنون مفتخر بودیم با ایجاد ساختار فروش مناسب، بررسی جداول مارکتینگی
          در ساختار به صورت داینامیک و فصلی، تعریف پورسانت ها، KPi و OKR مناسب
          تیمی و فصلی، ایجاد لین فروش outbound و دورکار و در نهایت آموزش نیروی
          فروش با هدف بال بردن میزان فروش شرکت ها در بازه های کوتاه مدت و بلند
          مدت خدمات ارائه دهیم
        </p>
      </Container>
      <Container style={{ marginTop: "10vh" }}>
        <ServicesList />
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
