import React from "react";
import { Card } from "react-bootstrap";
import "./Card.css";

function CustomCard(props) {
  return (
    <Card
      className="service-card"
      style={{
        textAlign: "center",
        position: "relative",
        direction: "rtl",
        height: "95%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "5vh",
        paddingTop: "5vh",
      }}
    >
      <Card.Img src={props.image} style={{ width: "40%", height: "40%" }} />
      <p>{props.Icon}</p>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>{props.description}</Card.Text>
        {/* <Button variant="primary">{props.buttonText}</Button> */}
      </Card.Body>
    </Card>
  );
}

export default CustomCard;
