import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import CustomCard from "../Card/CustomCard";
import { Modal, Button, Typography, Box } from "@mui/material";
import servicesList from "./Services.json";
import "./serviceslist.css";
import NewspaperGrid from "../textGrid/NewspaperGrid";
import cardsdata from "../textGrid/cardsdata.json";
import ModalForm from "../modalForm/ModalForm";
function InnerModal({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalForm />
    </Modal>
  );
}

function ServicesList() {
  const academyServices = servicesList[0].AcademiServices;
  const companyServices = servicesList[0].CompanyServices;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderServiceCards = (services) => {
    return services.map((service) => (
      <Grid item xs={12} sm={6} md={6} lg={3} key={service.id}>
        <CustomCard
          title={service.title}
          description={service.description}
          image={service.image}
          buttonText="Learn More"
        />
      </Grid>
    ));
  };

  return (
    <Grid container spacing={2} style={{ justifyContent: "center" }}>
      <div id="academy">
        <NewspaperGrid articles={cardsdata[0].Academy} />
        <div className="spacer">
          <h3>آکادمی فنو</h3>
        </div>
        <Grid container item xs={12}>
          {renderServiceCards(academyServices)}
        </Grid>
      </div>
      <div className="banner-box" id="freeConsultatiom">
        <div className="banner-place" onClick={handleOpen}>
          <h1>
            <span className="blinker">🔻</span>مشاوره رایگان با فرصت محدود
            <span className="blinker">🔻</span>
          </h1>
          <div className="banner-text">
            <p style={{ marginTop: "5vh", maxWidth: "70%" }}>
              برای دریافت مشاوره رایگان اینجا کلیک کنید تا به بخش فرم در خواست
              مشاوره هدایت شوید
            </p>
          </div>
        </div>
      </div>{" "}
      <div id="services">
        <NewspaperGrid articles={cardsdata[0].Companys} />
      </div>
      <Grid container item xs={12}>
        <div className="spacer">
          <h3>سرویس ها</h3>
        </div>
        {renderServiceCards(companyServices)}
      </Grid>
      <InnerModal open={open} handleClose={handleClose} />
    </Grid>
  );
}

export default ServicesList;
