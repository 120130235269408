import React from "react";
import "./NewspaperGrid.css";

function NewspaperGrid({ articles }) {
  return (
    <div className="main-box">
      <div className="newspaper-grid" style={{ direction: "rtl" }}>
        {articles.map((article) => (
          <div className="article" key={article.id}>
            <h2 className="title">{article.title}</h2>
            <p className="text">{article.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewspaperGrid;
